import "./SparkleButton.css";

const SparkleButton = ({ text, children, onClick }) => {
  return (
    <div className="sparkle-button">
      <cbutton onClick={onClick}>
        <span className="spark"></span>
        <span className="backdrop"></span>
        <div
          style={{
            color: "white",
            width: "50px",
            height: "50px",
            display: "flex"
          }}
        >
          {children}
        </div>
        <span className="text">{text}</span>
      </cbutton>
    </div>
  );
};

export default SparkleButton;
