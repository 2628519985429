import logo from "./assets/iibetLogo3.svg";
import topLogo from "./assets/iibet3.svg";
import {
  DownOutlined,
  DesktopOutlined,
  MobileOutlined,
  DownloadOutlined,
  CustomerServiceOutlined,
  GlobalOutlined
} from "@ant-design/icons";
import { Dropdown, Menu, Button } from "antd";
import "./App.css";
import WebGLComponent from "./components/WebGL/WebGL";
import SparkleButton from "./components/SparkleButton/SparkleButton";
import { useState } from "react";

const languages = [
  {
    key: "1",
    label: "English",
    title: "Welcomet to iiBet",
    subtitle: "Elevate Your Play, Ignite Your Luck!"
  },
  {
    key: "2",
    label: "हिंदी (Hindi)",
    title: "iiBet में आपका स्वागत है",
    subtitle: "अपने खेल को उन्नत करें, अपनी किस्मत को प्रज्वलित करें!"
  }
];

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState("1");

  const menu = (
    <Menu>
      {languages.map((lang) => (
        <Menu.Item
          key={lang.key}
          disabled={lang.disabled}
          onClick={() => setSelectedLanguage(lang.key)}
        >
          <a href={lang.href} target="_blank" rel="noopener noreferrer">
            {lang.label}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="App">
      <header className="App-header" style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
        >
          <WebGLComponent />
        </div>
        <div className="logo-container">
          <img
            src={topLogo}
            alt="logo1"
            style={{ pointerEvents: "none" }}
            className="App-side-logo"
          />
        </div>
        <div className="languages-container">
          <Dropdown overlay={menu} placement="bottomLeft">
            <div style={{ cursor: "pointer" }}>
              <GlobalOutlined
                style={{
                  marginRight: "6px",
                  color: "#fff",
                  fontSize: "2rem"
                }}
              />
            </div>
          </Dropdown>
          <CustomerServiceOutlined
            style={{
              fontSize: "2rem"
            }}
            onClick={() =>
              window.open(
                "https://tawk.to/chat/65560a3c91e5c13bb5b05c69/1hfc1fvqp",
                "_blank"
              )
            }
          />
        </div>
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          style={{ pointerEvents: "none" }}
        />
        <div className="welcome">
          {languages.find((lang) => lang.key === selectedLanguage)?.title}{" "}
        </div>
        <div className="play-now">
          {languages.find((lang) => lang.key === selectedLanguage)?.subtitle}{" "}
        </div>
        <div className="button-container">
          <div className="button-item">
            <SparkleButton
              text="Download"
              onClick={() =>
                (window.location.href = "https://gogo.dzptelaf.com/l8ioo4")
              }
            >
              <DownloadOutlined style={{ color: "white" }} />
            </SparkleButton>
          </div>
          <div className="button-item">
            <SparkleButton
              text="Mobile"
              onClick={() =>
                (window.location.href = "https://app.goplay000.com/")
              }
            >
              <MobileOutlined style={{ color: "white" }} />
            </SparkleButton>
          </div>
          <div className="button-item">
            <SparkleButton
              text="Desktop"
              onClick={() => (window.location.href = "https://goplay000.com/")}
            >
              <DesktopOutlined style={{ color: "white" }} />
            </SparkleButton>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
